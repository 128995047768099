import React from 'react';
import { Card } from 'react-bootstrap';
import EnquireNowButton from './EnquireNowButton';
import { Markup } from 'interweave';
import { NavLink } from 'react-router-dom';

const HandleProjectData = ({ projectData }) => {
    console.log("project", projectData)
    var url = "";
    if(projectData.slug === 'ira-the-square'){
        url = "https://thesquare.irarealty.in/";
    }else if(projectData.slug === 'aspiration'){
        url = "https://aspiration.irarealty.in/";
    }else if(projectData.slug === 'miracle'){
        url = "https://miracle.irarealty.in/";
    }

    console.log("url", url)
    return (
        <>
            {url !== "" ?

                <a href={url} target="_blank" style={{ textDecoration: 'none', color: '#303030' }}>
                    <div className='Featured-Projects-card2-background-Image' style={{ backgroundImage: `url(${projectData.image_url})` }}
                    >
                        <Card className='Featured-Projects-card2-background' >
                            <Card.Body>
                                <h2 className='featured-projects-title'>
                                    {projectData.title}
                                </h2>
                                <p>
                                    {projectData.location}
                                </p>
                                <p>
                                    {projectData.category_name}
                                </p>
                                <p>
                                    {projectData.smalldescription}
                                </p>
                                <div style={{ paddingBottom: '6%' }}>
                                    <Markup className='' content={projectData.description} />
                                </div>
                                {projectData.enable_enq_button == 'yes' && <EnquireNowButton buttonName="Enquire Now" />
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </a>
                :

                <NavLink to={`/project/${projectData.slug}`} style={{ textDecoration: 'none', color: '#303030' }}>
                    <div className='Featured-Projects-card2-background-Image' style={{ backgroundImage: `url(${projectData.image_url})` }}
                    >
                        <Card className='Featured-Projects-card2-background' >
                            <Card.Body>
                                <h2 className='featured-projects-title'>
                                    {projectData.title}
                                </h2>
                                <p>
                                    {projectData.location}
                                </p>
                                <p>
                                    {projectData.category_name}
                                </p>
                                <p>
                                    {projectData.smalldescription}
                                </p>
                                <div style={{ paddingBottom: '6%' }}>
                                    <Markup className='' content={projectData.description} />
                                </div>
                                {projectData.enable_enq_button == 'yes' && <EnquireNowButton buttonName="Enquire Now" />
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </NavLink>
            }

        </>
    );
};

export default HandleProjectData;