import React from 'react';
import Header from './Header';
import Footer from '../../views/Footer/Footer'
import { useSelector } from 'react-redux'
import EnquireForm from '../EnquireForm';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
    const showModal = useSelector((state) => state.showModal)
    const location = useLocation();

    const [loader, setloader] = React.useState(true);

    React.useEffect(() => {
        setloader(true);
    }, [location.pathname])


    //axios.defaults.baseURL = 'https://iracmsuat.irax.in/';
    //axios.defaults.baseURL = 'https://iracms.irax.in/';
    // axios.defaults.baseURL = 'https://www.irarealty.in/cms/';
    axios.defaults.baseURL = 'https://irarealty.in/cms/';
    // axios.defaults.baseURL = 'http://localhost/ira_cms/';

    var numberOfAjaxCAllPending = 0;

    axios.interceptors.request.use(function (config) {
        numberOfAjaxCAllPending++;
        document.body.classList.add('loading-indicator');

        // document.getElementsByClassName('body_content')[0].style.height = '100vh';
        // setloader(true);
        return config
    }, function (error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        numberOfAjaxCAllPending--;
        if (numberOfAjaxCAllPending == 0) {
            document.body.classList.remove('loading-indicator');
            // document.getElementsByClassName('body_content')[0].style.removeProperty("height");
            setloader(false);
        }
        return response;
    }, function (error) {
        numberOfAjaxCAllPending--;
        if (numberOfAjaxCAllPending == 0) {
            document.body.classList.remove('loading-indicator');
            // document.getElementsByClassName('body_content')[0].style.removeProperty("height");
            setloader(false);
        }
    });

    return (
        <>
            
            {!loader && <Header />}
            <div className='body_content'>
                {children}
            </div>
            <EnquireForm showModal={showModal} />
            {!loader && <Footer />}
        </>
    );
};

export default Layout;